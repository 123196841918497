<template>
	<div
		class="card-wrap"
		:class="[size]"
	>
		<TnCardVertical
			:blue="color === 'blue'"
			:gray="color === 'gray'"
			:border="color === 'white'"
			class="card"
			:loading="loading"
			shadow="m"
			:hover="cardIsLink"
			:to="(!card.button && cardIsLink && link && (link.to || link.href)) || ''"
			:arrow="!card.button && cardIsLink"
			:icon="cardIsIcon"
			:dark="dark"
			:iconSize="compSize"
			:compact="$store.state.isMobile"
		>
			<template
				#graphic
				v-if="image"
			>
				<NuxtImg
					:src="src"
					height="396"
					:alt="image.alt"
				/>
			</template>
			<template
				#badge
				v-if="card.newsBadgeText"
			>
				<TnBadge size="s">{{ card.newsBadgeText }}</TnBadge>
			</template>

			<template #default>
				<TnHeading
					v-if="title"
					type="h3"
					:dark="dark"
					:size="XlTitle ? 'm' : 's'"
					class="title"
					v-editable-string
					:class="[{ 'text-overlap': textOverlap }, { 'small-title': !XlTitle }]"
				>
					{{ title }}
				</TnHeading>
				<RichText
					v-if="text"
					:dark="dark"
					:text="text"
					class="main-text"
					:class="{ 'small-richtext': !XlTitle }"
					editable-html
				/>
				<TnList
					v-if="cardList.length"
					size="m"
					class="margin-top-l"
					:dark="dark"
				>
					<TnListItem
						v-for="(item, idx) in cardList"
						:key="idx"
						:title="item.listPoint"
						:text="item.description"
						:class="{ inactive: item.inactive }"
						:icon="item.inactive ? 'remove' : 'success'"
					/>
				</TnList>
				<TnLink
					v-if="modal && modalTriggerText"
					@click.stop="isModalOpen = true"
					class="modal margin-top-m"
				>
					<TnIcon
						name="info-circle-outline"
						class="margin-right-s"
					/>
					{{ modalTriggerText }}
				</TnLink>
				<Modal
					v-model="isModalOpen"
					@modal-closed="isModalOpen = false"
				>
					<TComponent
						v-for="(comp, i) in modalComponents"
						:key="i"
						toyt
						last
						:component="comp"
					/>
				</Modal>
				<p
					v-if="boldText"
					class="bold-text margin-top-m"
					v-editable-string
				>
					{{ boldText }}
				</p>
				<small
					v-if="smallText"
					class="small-text"
					v-editable-string
					>{{ smallText }}</small
				>
			</template>
			<template #actions>
				<template v-if="card.button && link && link.text">
					<div :class="['buttons-container']">
						<div :class="['primary-button-container', { 'button-spacing': secondButton }]">
							<TnButton
								v-bind="link"
								:secondary="card.buttonType === 'secondary' && !secondButton"
								size="m"
								:dark="dark"
								block
								:class="{ 'margin-bottom-m': secondButton && compSize == 's' }"
							/>
						</div>
						<div
							:class="['secondary-button-container', { 'button-spacing': secondButton }]"
							v-if="secondButton"
						>
							<TnButton
								v-if="secondButton"
								v-bind="secondButton"
								size="m"
								:dark="dark"
								secondary
								block
							/>
						</div>
					</div>
				</template>
				<LinkInline
					v-bind="link"
					v-else-if="link && link.text"
					size="l"
					:dark="dark"
					chevronRight
					editable
					style="width: 100%; justify-content: flex-end"
				/>
			</template>
		</TnCardVertical>
	</div>
</template>

<script>
import ResponsiveImageViewModel from "~/helpers/ViewModels/ResponsiveImageViewModel.js";
import LinkViewModel from "~/helpers/ViewModels/LinkViewModel.js";

export default defineNuxtComponent({
	name: "ListCardsCard",

	data() {
		return {
			isModalOpen: false,
		};
	},

	props: {
		card: {
			type: Object,
			required: true,
		},
		size: {
			type: String,
		},
		loading: {
			type: Boolean,
		},
		border: {
			type: Boolean,
		},
		color: {
			type: String,
		},
		dark: {
			type: Boolean,
			default: false,
		},
		XlTitle: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		src() {
			return this.card?.image?.image_md?.src?.split("?")[0];
		},
		cardIsLink() {
			const linksInText = this.text?.match(/<[\sa]+/g) || []; // Check if there are links in the richtext. Can't wrap link with links <a><a></a></a> breaks html.
			return (this.card?.link?.href || this.card?.link?.to) && !this.link?.text && linksInText?.length === 0;
		},
		badgeText() {
			return this.card.newsBadgeText;
		},
		badgeColor() {
			return this.card.badgeColor;
		},
		image() {
			if (this.card.image?.image_md) {
				const image = JSON.parse(JSON.stringify(this.card.image));
				image.image_md.src += "?height=396";
				return ResponsiveImageViewModel(image);
			} else {
				return null;
			}
		},
		cardIsIcon() {
			return this.card.image && (this.card.image.image_md.src.endsWith(".svg") || this.card.icon);
		},
		imageClass() {
			return this.cardIsIcon ? "icon" : "image";
		},
		title() {
			return this.card.title;
		},
		smallTitle() {
			return this.card.smallTitle || false;
		},
		text() {
			return this.card.text;
		},
		smallText() {
			return this.card.smallText;
		},
		boldText() {
			return this.card.boldText;
		},
		textOverlap() {
			return this.card.textOverlap;
		},
		link() {
			let link = this.card.link && LinkViewModel(this.card.link);
			link = link && JSON.parse(JSON.stringify(link));
			if (link && this.card.linkText) link.text = this.card.linkText;
			if (link && this.getQuery) {
				if (link.href) link.href = this.getLinkWithQuery(link.href);
				if (link.to) link.to = this.getLinkWithQuery(link.to);
			}
			return link;
		},
		secondButton() {
			if (this.card.button && this.card.linkText) {
				return this.card.secondButton && LinkViewModel(this.card.secondButton);
			} else {
				return null;
			}
		},
		cardList() {
			return (this.card.cardList && this.card.cardList.filter((point) => !!point.listPoint)) || [];
		},
		selectable() {
			return this.card.selected !== undefined;
		},
		getQuery() {
			return (
				Object.keys(useRoute()?.query).length > 0 &&
				Object.entries(useRoute()?.query)
					?.map((entry) => entry?.join("="))
					?.join("&")
			);
		},
		compSize() {
			return (
				{
					small: "s",
					medium: "m",
					large: "l",
				}[this.size] || "m"
			);
		},
		marginBottom() {
			if (this.compSize == "s" || this.compSize == "m") {
				return "margin-bottom-l";
			} else {
				return "";
			}
		},

		modal() {
			return this.card?.modal?.content;
		},

		modalTriggerText() {
			return this.modal?.trigger?.text;
		},

		modalComponents() {
			return this.modal?.components;
		},
	},

	methods: {
		getLinkWithQuery(link) {
			const query = link.split("?")[1];
			const mergedQuery = query
				? { ...useRoute()?.query, ...Object.fromEntries(new URLSearchParams(query)) }
				: useRoute()?.query;

			const mappedQuery =
				Object.keys(mergedQuery).length > 0 &&
				Object.entries(mergedQuery)
					?.map((entry) => entry?.join("="))
					?.join("&");

			return link.split("?")[0] + (mappedQuery ? `?${mappedQuery}` : "");
		},
	},
});
</script>

<style lang="scss" scoped>
@use "sass:math";

.card-wrap {
	position: relative;
	display: block;

	// flex-grow: 1;
	// flex-shrink: 1;
	width: auto;

	&.small,
	&.medium,
	&.large {
		.card {
			h3.title.text-overlap {
				@include breakpoint(mobile) {
					transform: translate(-50%, 55px);
				}
			}
		}
	}

	.image {
		height: 100%;
	}

	&.medium,
	&.large {
		.card {
			.buttons-container {
				display: flex;
				justify-content: center;
				flex-direction: column;
				gap: $spacing-m;

				.primary-button-container {
					width: 100%;

					&.button-spacing {
						margin-right: $spacing-s;
					}
				}

				.secondary-button-container {
					width: 100%;

					&.button-spacing {
						margin-left: $spacing-s;
					}
				}
			}
		}
	}

	&.medium {
		.title.text-overlap {
			transform: translate(-50%, 75px);
		}
	}

	&.large {
		.card {
			.title.text-overlap {
				transform: translate(-75%, 75px);
			}

			@include breakpoint(mobile) {
				padding-bottom: $spacing-l;
			}
		}
	}

	.card {
		width: 100%;
		height: 100%;
	}

	&.border {
		border: 1px solid $color-neutrals-200-tint;

		&.selected {
			border-color: $color-information-500-core;
			background: $color-background-blue-light;
		}
	}

	.card {
		.main-text {
			@include font-text-m;

			:deep(p strong) {
				font-weight: bold;
			}
		}

		.title {
			margin-bottom: $spacing-s;

			&.small-title {
				margin-bottom: 0;
			}

			&.text-overlap {
				position: absolute;
				left: 50%;
				top: 0;
				font-weight: bold;
			}
		}

		.richtext {
			@include font-text-m;

			// color: $color-neutrals-600-shade;
		}

		.bold-text {
			@include font-text-bold-l;
		}
	}
}

:deep() {
	.tn-list {
		color: $color-primary-mid;

		.tn-list--item.inactive {
			opacity: 0.5;
			color: $color-neutrals-black;

			.tn-list--content {
				.tn-list--titles {
					p.tn-paragraph.black {
						color: $color-neutrals-500-core !important;
					}
				}
			}
		}
	}
}

.small-text {
	color: $color-neutrals-700-shade;

	@include font-text-xs;
}

$small-card-width: 278px;
$medium-card-width: 380px;
$large-card-width: 582px;

.modal :deep(.link--text) {
	display: inline-flex;
	align-items: center;
	font-weight: bold;
}
</style>
