<template>
	<section class="list-cards">
		<div
			v-if="content.heading"
			class="heading padding-bottom-xl"
		>
			<TnHeading
				type="h2"
				size="m"
				v-editable-string
				>{{ content.heading }}</TnHeading
			>
			<TnParagraph
				v-if="content.heading && content.ingress"
				size="m"
				class="ingress"
				v-editable-string
			>
				{{ content.ingress }}
			</TnParagraph>
		</div>
		<ImageResponsive
			v-if="content.image"
			v-bind="content.image"
			class="ingress-image"
		/>
		<div
			v-if="isProductCards"
			:class="cardLayoutClasses"
			style="display: grid"
		>
			<ProductCard
				v-for="(card, index) in cards"
				:card="card"
				:key="`product-card-${index}`"
			/>
		</div>
		<div
			v-else
			:class="cardLayoutClasses"
		>
			<ListCardsCard
				:card="card"
				v-for="(card, index) in cards"
				:key="`card-${index}`"
				:border="!hasBackground"
				:color="color"
				:size="size"
				:dark="dark"
				:loading="loading"
				@click="handleClick(card, index)"
				:class="{ 'has-bg': hasBackground }"
				:XlTitle="XlTitle"
			/>
		</div>
	</section>
</template>

<script>
import { mapGetters } from "vuex";
import LinkViewModel from "~/helpers/ViewModels/LinkViewModel.js";

export default defineNuxtComponent({
	name: "ListCards",

	props: {
		component: {
			type: Object,
		},
		loading: {
			type: Boolean,
		},
    dark: {
      type: Boolean,
      default: false,
    },
	},

	methods: {
		handleClick(card, index) {
			card.index = index;
			if (card.link?.externalUrl === "javascript:") this.$emit("cardClicked", card);
		},
	},

	computed: {
		...mapGetters(["isMobile"]),
		content() {
			return this.component?.content || {};
		},
		cardLayoutClasses() {
			if (this.isProductCards) return getLayoutClasses(this.cards.length);

			if (this.size === "large") return getLayoutClasses(2);
			if (this.size === "medium") return getLayoutClasses(3);
			else return getLayoutClasses(this.cards.length);
		},
		isProductCards() {
			return (
				this.component.template &&
				(this.component.template.name === "product--card" || this.component.template.name === "product-card")
			);
		},
		cards() {
			if (this.isProductCards) return this.content.products;
			if (!this.content || !this.content.cards) return [];

			return this.content.cards.map((card, index) => {
				return {
					...card,
					link: {
						...LinkViewModel(card.link),
					},
					icon: card.icon || (this.component.schema && !this.component.schema._id.endsWith("list--cards-pioneer")),
					default: this.content.default === index + 1,
				};
			});
		},
		hasBadge() {
			return (
				this.component.content.cards &&
				this.component.content.cards.some((card) => {
					return card.newsBadgeText;
				})
			);
		},
		hasBackground() {
			return this.color !== "transparent";
		},
		size() {
			return this.content.size || "medium";
		},
		color() {
			return this.content.backgroundColor || "transparent";
		},
		XlTitle() {
			return this.content.xltitle;
		},
	},
});
</script>

<style lang="scss" scoped>
.bg-dark {
	.list-cards {
		.heading,
		.ingress {
			color: $color-neutrals-white;
		}

		.card-wrap:not(.has-bg) {
			:deep(.card) {
				color: $color-neutrals-white;
			}
		}
	}
}

.list-cards {
	display: flex;
	gap: $spacing-m;
	justify-content: flex-start;
	flex-wrap: wrap;

	.heading {
		width: 100%;
	}

	.ingress {
		padding-top: $spacing-l;
		font-family: $font-family-telenor-ui;
	}

	.ingress-image {
		margin-bottom: 60px;
	}
}
</style>
